/**
 * main.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */

@import 'tailwindcss/base';

/**
 * This injects any component classes registered by plugins.
 *
 */

@import 'tailwindcss/components';

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

@import './elements/html';
@import './elements/body';
@import './elements/img';
@import './elements/p';
@import './elements/abbr';
@import './elements/button';
@import './elements/table';
@import './components/navigation';
@import './components/rte';
@import './components/content';
@import './components/page-header';
@import './components/swiper';

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */

@import 'tailwindcss/utilities';
