.content {
  line-height: theme('lineHeight.tight');

  & table {
    line-height: theme('lineHeight.normal');
  }

  /* stylelint-disable-next-line */
  & > p,
  & > div {
    padding-block: 0.25rem;
  }
  & > :is(h2, h3) {
    line-height: theme('lineHeight.normal');
    margin-block-start: 1.875rem;
    padding-block: 0.0625rem 1.9375rem;

    &:not(:first-child) {
      border-top: 1px solid;
    }

    & + * {
      border-top: none;
    }
  }
}
