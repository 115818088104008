table {
  width: 100%;

  & th {
    width: 7.75rem;
    font-weight: normal;
    text-align: start;
    vertical-align: top;

    @screen md {
      width: 10.625rem;
    }
  }

  & tr td,
  & tr th {
    border-top: 0.0625rem solid;
  }
}
