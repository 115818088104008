html {
  height: 100%;
  background-color: theme('colors.white-100');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  transition: background-color 0.2s linear;

  &.cookies-not-accepted {
    overflow-y: hidden;
  }
}
