<template>
  <div class="swiper-container" ref="swiperEl">
    <div class="swiper-wrapper">
      <slot
        :infoVisible="infoVisible"
        :updateFontSize="updateFontSize"
        :toggleInfo="toggleInfo"
      />
    </div>
    <div class="buttons" v-if="!infoVisible">
      <button class="pointer-cursor flex-auto" @click="slidePrev">
        <span class="sr-only">Previous slide</span>
      </button>
      <button class="pointer-cursor flex-auto" @click="slideNext">
        <span class="sr-only">Next slide</span>
      </button>
    </div>
    <div
      class="flex justify-between leading-none space-x-8 md:space-x-10 absolute bottom-0 inset-x-0 z-10"
    >
      <button
        class="swiper-button pt-5 pb-3 md:pb-5 pl-10px md:pl-5 flex-auto text-left"
        @click="slidePrev"
      >
        <span class="sr-only">Previous slide</span>
        <svg focusable="false">
          <use xlink:href="#arrow" />
        </svg>
      </button>
      <button @click="toggleInfo" class="text-base md:text-lg info-button">
        {{ infoText }}
      </button>
      <button
        class="swiper-button pt-5 pb-3 md:pb-5 pr-10px md:pr-5 flex-auto text-right"
        @click="slideNext"
      >
        <span class="sr-only">Next slide</span>
        <svg class="ml-auto transform rotate-180" focusable="false">
          <use xlink:href="#arrow" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Swiper, Lazy, History, Keyboard } from 'swiper/js/swiper.esm';
import {
  defineComponent,
  ref,
  onMounted,
  Ref,
  computed,
  onBeforeUnmount,
  watch,
} from '@vue/composition-api';
import { updateFontSize } from '../utils';

Swiper.use([Lazy, History, Keyboard]);

export default defineComponent({
  setup() {
    const swiperEl: Ref<HTMLElement | null> = ref(null);
    const swiper: Ref<Swiper | null> = ref(null);
    const infoVisible = ref(false);
    const activeSlide: Ref<HTMLElement | null> = ref(null);
    const speed = 500;
    let navigationItems: HTMLAnchorElement[] | null;

    const slideNext = () => {
      if (swiper.value) {
        swiper.value.slideNext();
      }
    };

    const slidePrev = () => {
      if (swiper.value) {
        swiper.value.slidePrev();
      }
    };

    const openInfo = () => {
      infoVisible.value = true;
    };

    const closeInfo = () => {
      infoVisible.value = false;
    };

    const toggleInfo = () => {
      infoVisible.value = !infoVisible.value;
    };

    const infoText = computed(() => {
      if (infoVisible.value) {
        return 'Titel';
      }
      if (activeSlide.value && activeSlide.value.dataset.infoButtonLabel) {
        return activeSlide.value.dataset.infoButtonLabel;
      }
      return 'Info';
    });

    const handleKeyboardEvents = (event: KeyboardEvent) => {
      if (event.keyCode === 40) {
        openInfo();
      }
      if (event.keyCode === 38) {
        closeInfo();
      }
    };

    const updateActiveSlide = () => {
      if (swiper.value && swiper.value.slides[swiper.value.activeIndex]) {
        activeSlide.value = swiper.value.slides[swiper.value.activeIndex];
      }
    };

    const setActiveNavItem = (slug: string) => {
      if (navigationItems) {
        navigationItems.forEach(item => {
          const url = new URL(item.href);
          item.classList.remove('active');

          if (url.pathname.substr(1) === slug) {
            item.classList.add('active');
          }
        });
      }
    };

    const handleNavigationItemClick = (event: Event) => {
      const target = event.target as HTMLElement;

      if (target.dataset.slide && swiper.value) {
        event.preventDefault();

        const index = Array.from(swiper.value.slides).findIndex(slide => {
          return slide.dataset.history === target.dataset.slide;
        });
        swiper.value.slideTo(index, speed);
        setTimeout(() => {
          openInfo();
        }, 200);
      }
    };

    onMounted(() => {
      if (swiperEl.value) {
        const navigation = document.getElementById('navigation');

        swiper.value = new Swiper(swiperEl.value, {
          keyboard: true,
          loop: true,
          speed,
          history: {
            replaceState: true,
            key: '',
          },
          lazy: {
            loadPrevNext: true,
          },
          preloadImages: false,
          on: {
            init() {
              if (window.location.hash === '#info') {
                setTimeout(() => {
                  openInfo();
                }, 500);
              }
              setTimeout(() => {
                updateActiveSlide();
              }, 100);
              updateFontSize();
            },
            slideChange() {
              updateActiveSlide();
            },
            transitionStart() {
              infoVisible.value = false;
            },

            slideChangeTransitionEnd() {
              if (swiper.value) {
                swiper.value.slideToLoop(swiper.value.realIndex, 0, false);
              }
            },
          },
        });

        if (navigation) {
          navigationItems = Array.from(
            navigation.querySelectorAll('.navigation-item'),
          );
          navigationItems.forEach(item => {
            item.addEventListener('click', handleNavigationItemClick);
          });
        }
      }

      window.addEventListener('keyup', handleKeyboardEvents);
    });

    watch(activeSlide, () => {
      if (activeSlide.value && activeSlide.value.dataset.title) {
        document.title = activeSlide.value.dataset.title;

        setActiveNavItem(activeSlide.value.dataset.history || '');
      }
    });

    watch(infoVisible, () => {
      if (infoVisible.value) {
        if (activeSlide.value && activeSlide.value.dataset.bgColor) {
          document.documentElement.classList.add(
            `bg-${activeSlide.value.dataset.bgColor}`,
          );
          document.documentElement.classList.add(
            `info-${activeSlide.value.dataset.history}`,
          );
        }

        if (swiper.value) {
          swiper.value.allowTouchMove = false;
        }
      } else {
        Array.from(document.documentElement.classList)
          .filter(className => className !== 'cookies-not-accepted')
          .forEach(className =>
            document.documentElement.classList.remove(className),
          );

        if (swiper.value) {
          swiper.value.allowTouchMove = true;
        }
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keyup', handleKeyboardEvents);
    });

    return {
      swiperEl,
      slidePrev,
      slideNext,
      toggleInfo,
      infoVisible,
      infoText,
      activeSlide,
      updateFontSize,
    };
  },
});
</script>

<style src="swiper/css/swiper.css"></style>

<style lang="postcss">
.swiper-lazy {
  opacity: 0;
}

.swiper-lazy-loaded {
  opacity: 1;
}

.swiper-button {
  position: relative;

  & svg {
    height: 0.75rem;
    width: 100%;

    @screen md {
      height: 1.15625rem;
    }
  }
}

.buttons {
  @apply absolute w-full top-0 left-0 flex z-10;

  bottom: 7rem;

  @screen md {
    bottom: 10rem;
  }
}

.info-button {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 3.375rem;
  padding-bottom: 0.125rem;

  @screen md {
    flex-basis: 5.625rem;
    padding-bottom: 0.9375rem;
  }
}
</style>
