.page-header {
  @apply px-10px py-3 font-mono text-xs leading-none bg-inherit;

  @screen md {
    @apply p-5 text-sm;
  }

  @nest .cookies-not-accepted & {
    @apply bg-white-100;
  }
}
