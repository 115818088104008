.swiper-slide {
  @apply px-10px;

  padding-block: 2.125rem 3.375rem;

  @screen md {
    @apply px-5;

    padding-block: 3.375rem 5.3125rem;
  }
}
