.navigation {
  @nest html:not(.bg-green) & {
    & li:first-child {
      color: theme('colors.green');
    }
  }
}

.navigation-item {
  &::before {
    display: inline-block;
    width: 0.45rem;
    height: 0.45rem;
    border: 1px solid;
    background-color: currentColor;
    border-radius: 999px;
    content: '';
    margin-inline-end: 0.375rem;

    @screen md {
      width: 0.625rem;
      height: 0.625rem;
      margin-inline-end: 0.5625rem;
    }
  }

  &:hover,
  &.active {
    &::before {
      background-color: transparent;
    }
  }
}

.navigation-icon {
  width: auto;
  height: 0.5rem;
  fill: currentColor;
  margin-block-start: 0.0625rem;
  margin-inline-end: 0.375rem;
  stroke: currentColor;
  vector-effect: none-scaling-stroke;

  @screen md {
    height: 0.625rem;
    margin-block-start: 0.125rem;
    margin-inline-end: 0.5625rem;
  }
}

.navigation-icon-parent {
  &:hover,
  &.active {
    & .navigation-icon {
      fill: none;
    }
  }
}
